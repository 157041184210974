//FOR VIEWING
//Redirects here ONLY from CourseTextChallenge

//React
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

//Services/Helpers
import parsePageJSONForVisibility from 'logic/gridslate/nodePage/parsePageJSONForVisibility';
import { useAtom } from 'jotai';
import { courseProgressAtom, courseAtom } from 'atom';
import { toast } from 'react-toastify';

//CSS
import "css/prosemirror.css";
import "css/custom-tiptap-styles.css";

//Logics
import { Container } from "view_components/helper/HelperComponents";

//Components
import RenderCourseContent from "views/render/RenderCourseContent";
import CheckpointTimeline from 'views/courseview/courseContentElements/subcomponents/CheckpointTimeline';

//Classes
import { Status } from "classes/enums/Status";
import { CourseContent } from 'classes/course/CourseContent';
import { CourseChallenge } from 'classes/course/CourseChallenge';

const ViewTextChallengeSystem = () => {

  const { courseContentId } = useParams();
  const [parsedContent, setParsedContent] = useState<any>("");
  const [courseContent, setCourseContent] = useState<CourseContent | null>(null);
  const [courseProgress, setCourseProgress] = useAtom(courseProgressAtom);
  const [courseChallenge, setCourseChallenge] = useState<CourseChallenge | null>(null);
  const [nodeCourse, setCourse] = useAtom(courseAtom);

  const navigate = useNavigate();

  //TODO: Redirect back to course page if nodeCourse not loaded
  //OR load nodeCourse here with selective load

  useEffect(() => {
    if (nodeCourse.status === Status.unloaded) {
      toast.error("Course not loaded. Redirecting to course browser.");
      navigate("/CourseBrowser");
    } else {
      //Set course content
      let thisCourseContent = nodeCourse.courseContents.find((x) => x.id === courseContentId);
      if (thisCourseContent) {
        let thisChallenge = courseProgress.challengeProgresses.find((x) => x.courseContentId === courseContentId);
        let thisParsedContent = JSON.parse(thisCourseContent.contentDocument);
        thisParsedContent = parsePageJSONForVisibility(thisParsedContent, thisChallenge);
        setParsedContent(thisParsedContent);
        setCourseContent(thisCourseContent);
      } else {
        toast.error("Course content not found. Redirecting to course page.");
        navigate("/ViewCourse/" + nodeCourse.id);
      }
      //Set course challenge, if it exists
      //TODO: Check limitation: ONLY one challenge per course content, otherwise only the first one will be set
      let thisCourseChallenge = nodeCourse.courseChallenges.find((x) => x.courseContentId === courseContentId);
      if (thisCourseChallenge) {
        setCourseChallenge(thisCourseChallenge);
      } else {
        console.warn("Course challenge not found.");

      }
    }

  }, []);

  //TODO: Parse visibility

  useEffect(() => {
    if (courseProgress.visibilityUpdated) {
      console.log("Visibility updated reached");
      let challengeProgress = courseProgress.challengeProgresses.find((x) => x.courseContentId === courseContentId);
      let thisParsedContent = parsePageJSONForVisibility(parsedContent, challengeProgress);
      setParsedContent(thisParsedContent);
      setCourseProgress({ ...courseProgress, visibilityUpdated: false });
    }
  }, [courseProgress.visibilityUpdated]);

  return (
    <Container>
      {/* <Button onClick={() => parseVisibility()}>Parse visibility</Button> */}
      {/* {JSON.stringify(parsedContent)} */}
     
      {/* {"Challenge: " + JSON.stringify(courseChallenge)}
      <div className="my-4"></div>
      {"Progress: " +  JSON.stringify(courseProgress)}
       */}
       {courseChallenge && <CheckpointTimeline contentDocument={parsedContent} courseChallenge={courseChallenge} />}
      
      <div className="mb-96 pt-12">
        {parsedContent !== "" &&
          <div>
            {/* {nodePage && nodePage.type === "courseText" && <CheckpointTimeline contentDocument={parsedContent} nodePageId={nodePageId?nodePageId:""} />}   */}
            <div className="max-w-none prose prose-sm sm:prose-base lg:prose-base xl:prose-xl">
              <RenderCourseContent contentJSON={parsedContent} courseChallenge={courseChallenge} renderCheckpoints={true} />
            </div>
          </div>}
        {!courseContent && <div>Loading...</div>}
      </div>
    </Container>
  )
}

export default ViewTextChallengeSystem;