//React
import { useNavigate } from 'react-router-dom';

//UI
import { Button } from 'flowbite-react';

//Services
import { useAtom } from 'jotai';
import { breadCrumbsAtom } from 'atom';

//Logics
import breadCrumbLogic from 'logic/utility/breadCrumbLogic';

//Classes

//Components

type Props = {
    courseContentId: string;
    courseContentName: string;
}

const CourseTextViewElement = (props: Props) => {

    const { courseContentId, courseContentName } = props;
    const navigate = useNavigate();
    const [breadCrumbs, setBreadCrumbs] = useAtom(breadCrumbsAtom);
    
    return (
        <div>
            <div className="border border-col-p2 border-4 m-2 flex flex-col items-center justify-center relative">
                <div className="bg-col-p2 text-white rounded-br-md font-bold text-xs md:text-sm p-1 md:p-2 absolute top-0 left-0">Course Text</div>
                <div className="text-lg md:text-2xl font-black mx-2 md:mx-4 mt-6 md:my-2">{courseContentName}</div>
              
                <Button className="my-2" onClick={() => {
                    //To CourseContentViewer
                    setBreadCrumbs(breadCrumbLogic.addBreadCrumb("Text:" + courseContentName, "/CourseTextViewWrapper/" + courseContentId, breadCrumbs));
                    navigate("/CourseTextViewWrapper/" + courseContentId);
                }}>View Text</Button>
            </div>
            
        </div>
    )

}

export default CourseTextViewElement;


