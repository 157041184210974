//import { Slide, Fade } from "react-awesome-reveal";
import {Container} from 'view_components/helper/HelperComponents';
import useS3Fetch from 'services/useS3Fetch';
//import "animate.css/animate.min.css";

const Test = () => {
    //const { pageRef, pageId } = props;

    //const { payload: navbarS3, isPending: isPendingNavbar, error: errorNavbar } = useS3Fetch("https://s3.ap-northeast-2.amazonaws.com/gridslate.frontend/jamesdanielsen/production/navbar/navbar.json");

    return (
        <Container>
                
                <div>
                    {/* {JSON.stringify(navbarS3)} */}
                <h1>Test</h1>
                
                <p className="animate__animated animate__bounce">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque

                    <br />
                    <br />
                </p>
                </div>   
         
        </Container>

    );
}

export default Test;