//React
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//UI
import { Button } from 'flowbite-react';

//Services
import { useAtom } from 'jotai';
import { courseProgressAtom, breadCrumbsAtom } from 'atom';
import time from 'logic/utility/time';
import breadCrumbLogic from 'logic/utility/breadCrumbLogic';

//Logics

//Classes
import { CourseChallenge } from 'classes/course/CourseChallenge';
import { ChallengeProgress } from  "classes/courseprogress/ChallengeProgress";

//Components

type Props = {
    //This is coming from atttributes of the parent component
    courseChallenge: CourseChallenge;
}

const CourseTextChallengeElement = (props: Props) => {

    const { courseChallenge } = props;
    const navigate = useNavigate();
    //const [nodeCourse] = useAtom(courseAtom);
    const [breadCrumbs, setBreadCrumbs] = useAtom(breadCrumbsAtom);
    const [courseProgress] = useAtom(courseProgressAtom);
    const [thisChallengeProgress, setThisChallengeProgress] = useState<ChallengeProgress | null>(null);
    
    //const thisProgressChallenge = courseProgress.challengeProgresses.find((x) => x.challengeId === courseChallenge.id) as ChallengeProgress;
    //TODO: throw error if thisChallenge is undefined, but below won't render if it is undefined
    //TODO: throw error if thisProgressChallenge is undefined, MAYBE

    useEffect(() => {
        //console.log(" attribute course challenge:", courseChallenge);
        let thisProgressChallenge = courseProgress.challengeProgresses.find((x) => x.challengeId === courseChallenge.id) as ChallengeProgress;
        if (!thisProgressChallenge) {
            console.warn("ChallengeProgress not found for challenge: " + courseChallenge.id);
        } else {
            setThisChallengeProgress(thisProgressChallenge);
        }
    }, []);

    const renderCheckpointProgress = () => {
 
        //Should not possible
        if (!thisChallengeProgress) {
            return <div>Challenge Progress not found</div>
        }

        const renderCheckmark = (elementId: string) => {
            let checkpoint = thisChallengeProgress.checkpointProgresses.find((x) => x.elementId === elementId);
            //TODO: Change to isCompleted in future
            if (checkpoint && checkpoint.dateCompleted !== 0) {
                return <span>✔️</span>
            }
            return <span>❌</span>
        }
    
        return (
            <div className={thisChallengeProgress.isComplete ? "flex my-2 border border-4 p-2 border-green-500": "flex my-2 border border-4 p-2 border-slate-500"} >
                  {courseChallenge.checkpoints.map((checkpoint, index) => {
                    return <span key={checkpoint.elementId + index} className="font-gray text-xs md:text-sm">
                        Checkpoint
                        <span>{renderCheckmark(checkpoint.elementId)}</span>
                        </span>
                })}
            </div>)
    }

    const getButtonText = () => {
        let buttonText = "Start Challenge";
        if (thisChallengeProgress) {
            if (thisChallengeProgress.isComplete) {
                buttonText = "Review Challenge";
            }
            else if (thisChallengeProgress && thisChallengeProgress.checkpointProgresses.length > 0) {
                buttonText = "Continue Challenge";
            } 
        }
        
        return buttonText;
    }

    return (
        <div>
            {/* {thisChallengeProgress && <div>Progress object: {JSON.stringify(thisChallengeProgress)}</div>}
            {thisChallengeProgress && <div>Is complete: {thisChallengeProgress.isComplete?"true":"false"}</div>} */}
            {courseChallenge && thisChallengeProgress && <div className="border border-col-p2 border-4 m-2 flex flex-col items-center justify-center relative">
                <div className="bg-col-p2 text-white rounded-br-md font-bold text-xs md:text-sm p-1 md:p-2 absolute top-0 left-0">Text Challenge</div>
                <div className="text-lg md:text-2xl font-black mx-2 md:mx-4 mt-6 md:my-2">{courseChallenge.name}</div>
                <div className="flex my-2">
                    <div className="mr-8">Start Date: {time.toCoolTimeString(courseChallenge.startDate)}</div>
                    <div className="mr-8">Due Date: {time.toCoolTimeString(courseChallenge.dueDate)}</div>
                    {thisChallengeProgress.isComplete && <div className="text-green-500">Completed: {time.toCoolTimeString(thisChallengeProgress.dateCompleted)}</div>}
                </div>
                <div>
                    {renderCheckpointProgress()}
                </div>

                <Button className="my-2" onClick={() => {
                    //To CourseContentViewer
                    setBreadCrumbs(breadCrumbLogic.addBreadCrumb("Text Challenge:" + courseChallenge.name, "/ViewTextChallenge/" + courseChallenge.courseContentId, breadCrumbs));
                    navigate("/ViewTextChallenge/" + courseChallenge.courseContentId);
                }}>{getButtonText()}</Button>
            </div>}
            {!thisChallengeProgress && <div>Progress object not found</div>}  
        </div>
    )

}

export default CourseTextChallengeElement;


