//React
import React from "react";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";

//UI
import 'reveal.js/dist/reveal.css'
import 'reveal.js/dist/theme/simple.css'
import 'css/wordflash.css';
import Reveal from 'reveal.js';
import { Modal, Button, Label, RangeSlider, ToggleSwitch } from "flowbite-react";
import { Container, CheckboxAndLabel } from "view_components/helper/HelperComponents";

//Services
import { useWindowDimensions } from "logic/hooks/useWindowDimensions";
import useSound from 'use-sound';
import apiService from "services/apiService";
import config from "config";


//Logics

//Components
import Counter from "view_components/shared/Counter";

//Store

//Classes
import { QuestionAnswer } from "classes/synapp/wordflash/QuestionAnswerFlashGame";
import { WordSetGroup, SingleWord } from "classes/synapp/wordflash/WordSetGroup";
import { QASetGroup } from "classes/synapp/wordflash/QASetGroup";
import {FlashGameSession} from "classes/logging/FlashGameSession";

//Assets
import passSound from 'assets/sound/pass.mp3';
import roundCompleteSound from 'assets/sound/tada.mp3';
import correctSound from 'assets/sound/yay.mp3';
import tickSound from 'assets/sound/tick.mp3';
import warningTickSound from 'assets/sound/warning-tick.mp3';
import tenSecondsSound from 'assets/sound/ten-seconds.mp3';

function RunFlashGame() {

  //============================================================== CLASSES ===============================

  //============================================================== VARIABLES & SETUP ===============================

  //const listOfRevealThemes = ["black", "white", "league", "sky", "beige", "simple", "serif", "blood", "moon", "night", "solarized"];

  const { state } = useLocation();
  const { gameObject, gameType } = state; // Read values passed on state

  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [score, setScore] = useState(0);

  //const [ revealTheme, setRevealTheme ] = useState("black");

  const [slidesRemaining, setSlidesRemaining] = useState(0);
  const [slideTexts, setSlideTexts] = useState<QuestionAnswer[] | string[]>([]);
  const { viewHeight, viewWidth } = useWindowDimensions();

  const [isPreview, setIsPreview] = useState(false);
  const deck = useRef<any | null>(null);

  const [wordGame, setWordGame] = useState<WordSetGroup>(new WordSetGroup("", "", []));
  const [qAGame, setQAGame] = useState<QASetGroup>(new QASetGroup("", ""));

  const timer = useRef<any | null>(null);
  //const [tickIntervalInMs, setTickIntervalInMs] = useState(1000);
  const tickIntervalInMs = 1000;  
  const [timerDelay, setTimerDelay] = useState<number | null>(1000); //For pausing and unpausing
  const [duration, setDuration] = useState(60);
  const [timeRemaining, setTimeRemaining] = useState<number>(60);

  const [gamePlaying, setGamePlaying] = useState(false);
  const [gameFinished, setGameFinished] = useState(false);
  const [paused, setPaused] = useState(false);

  const [randomizeSlides, setRandomizeSlides] = useState(false);

  //Options for QA
  const [showAnswers, setShowAnswers] = useState(true);
  const [howAboutYou, setHowAboutYou] = useState(false);
  const [tempShowQA, setTempShowQA] = useState(false);

  //Options for Words
  const [selectedWordSubsets, setSelectedWordSubsets] = useState([0]);
  //const [selectedWordSubSetIndex, setSelectedWordSubsetIndex] = useState(0);
  //const [showEditWords, setShowEditWords] = useState(false);

  const [showOptions, setShowOptions] = useState(false);

  const [volume, setVolume] = useState(0.05);

  const [playCorrect] = useSound(correctSound, { volume: volume });
  const [playTick] = useSound(tickSound, { volume: volume });
  const [playWarningTick] = useSound(warningTickSound, { volume: volume });
  const [playPass] = useSound(passSound, { volume: volume });
  const [playRoundComplete] = useSound(roundCompleteSound, { volume: volume });
  const [playTenSeconds] = useSound(tenSecondsSound, { volume: volume });

  const [durationSetting, setDurationSetting] = useState(3);

  const [gameSessionObject, setGameSessionObject] = useState<FlashGameSession>(new FlashGameSession());

  //============================================================== Initialization and UseEffect ===============================

  useEffect(() => {
    //console.log('Rerendering...');
    if (!deck.current) {
      deck.current = new Reveal({});
      deck.current.initialize({ embedded: true, controls: false, overview: true, touch: false, scrollActivationWidth: null });
    }

    initializeGame();

  }, []);

  const initializeGame = () => {

    //TODO: check if gameObject and gameType are valid

    if (gameType === "qa") {
      setSlidesRemaining(gameObject.qaSets.length);
      setSlideTexts(gameObject.qaSets);
      setQAGame(gameObject);
    }
    if (gameType === "word") {
      console.log(gameObject);
      let words = [...gameObject.wordSets[0].words.map((word: SingleWord) => word.word) as string[]];
      words = words.sort(() => Math.random() - 0.5);

      setSlidesRemaining(gameObject.wordSets[0].words.length);
      setSlideTexts(words);
      setWordGame(gameObject);
      setRandomizeSlides(true);
    }
  }

  const getWordsFromSubsets = (tempSelectedWordSubSets: number[]) => {
    let words = [] as string[];
    for (let i in tempSelectedWordSubSets) {
      words = words.concat(wordGame.wordSets[tempSelectedWordSubSets[i]].words.map((word) => word.word) as string[]);
    }
    return words;
  }

  //============================================================== Logging ===============================

  const logFlashGameSessionStart = async () => {
    let newGameSession = new FlashGameSession();
    newGameSession.type = gameType;
    newGameSession.setId = gameObject.id;
    newGameSession.subSetsSelected = selectedWordSubsets;
    newGameSession.totalWords = slidesRemaining;
    newGameSession.isPublic = gameObject.isPublic;
    
    let response = await apiService.post(config.apiUrl + '/SlideLogging/LogFlashGameSession', newGameSession);
    if (response.success) {
      setGameSessionObject(response.payload as FlashGameSession);
      console.log('Game session logged, with id: ' + response.payload.id);
    } else {
      console.log('Error logging game session');
    }
  } 

  const logFlashGameSessionEnd = async () => {
    let newGameSession = gameSessionObject;
    newGameSession.totalCorrect = score;
    newGameSession.gameWasFinished = true;
    let response = await apiService.post(config.apiUrl + '/SlideLogging/LogFlashGameSession', newGameSession);
    if (response.success) {
      console.log('Game session log updated');
    } else {
      console.log('Error updating game session log');
    }
  }

  

  //============================================================== TIMER Logic ===============================

  const timerTick = (startTime: number, duration: number) => {
    //Check difference between start time and now
    let currentTime = new Date().getTime();
    let timeDifference = currentTime - startTime; // this is in thousand miliseconds
    let thisTimeRemaining = (duration) - (timeDifference / 1000);
    //Round up time remaining to nearest second
    thisTimeRemaining = Math.round(thisTimeRemaining);

    if (thisTimeRemaining === 10) {
      playTenSeconds();
    }
    else if (thisTimeRemaining > 5) {
      playTick();

    } else if (thisTimeRemaining !== 0) {
      playWarningTick();
    }

    //Set new time remaining
    setTimeRemaining(thisTimeRemaining);

    //If time remaining is less than 0, end mcq
    if (thisTimeRemaining <= 0) {
      endGame();
    }
  }

  const durationLookup = (value: number) => {
    if (value === 0) return 15;
    if (value === 1) return 30;
    if (value === 2) return 45;
    if (value === 3) return 60;
    if (value === 4) return 90;
    if (value === 5) return 120;
    return 15;
  }

  const durationLabelLookup = (value: number) => {
    if (value === 0) return '00:15';
    if (value === 1) return '00:30';
    if (value === 2) return '00:45';
    if (value === 3) return '01:00';
    if (value === 4) return '01:30';
    if (value === 5) return '02:00';
    return '00:15';
  }
  //============================================================== Game Logic ===============================

  const endGame = () => {
    clearInterval(timer.current);
    timer.current = null;
    setGamePlaying(false);
    setPaused(false);
    setGameFinished(true);
    setTimeRemaining(duration);
    playRoundComplete();
    logFlashGameSessionEnd();
  }

  const startGame = () => {
    let startTime = new Date().getTime();
    setGamePlaying(true);
    setTimeRemaining(duration);

    //Logging
    logFlashGameSessionStart();

    timer.current = (setInterval(() => {
      timerTick(startTime, duration);
    }, tickIntervalInMs));

    nextSlide();
  }

  const nextSlide = () => {
    if (gameType === "qa") {
      setTempShowQA(false);
    }
    if (currentSlide < slideTexts.length) {
      deck.current.slide(currentSlide + 1, 0, 0);
      setCurrentSlide(currentSlide + 1);
    }
    else {
      endGame();
    }
  }

  const previewChangeSlide = (isNext: boolean) => {
    if (isNext) {
      if (currentSlide < slideTexts.length + 1) {
        setScore(score + 1);
        deck.current.next();
        setCurrentSlide(currentSlide + 1);
      }
    }
    else {
      if (currentSlide > 0) {
        setScore(score - 1);
        deck.current.prev();
        setCurrentSlide(currentSlide - 1);
      }
    }
  }

  const updateWordGame = (newSelectedWordSubSets: number[]) => {
    setSelectedWordSubsets(newSelectedWordSubSets);
    let words = getWordsFromSubsets(newSelectedWordSubSets);
    setSlidesRemaining(words.length);
    setSlideTexts(words);
  }

  //============================================================== Handle Clicks ===============================

  const handlePreview = () => {
    //reset score and words remaining
    setScore(0);
    setSlidesRemaining(slideTexts.length);
    deck.current.slide(0, 0, 0);
    setCurrentSlide(0);
    setIsPreview(!isPreview);
  }

  const handlePauseGame = () => {
    //if (gamePlaying) {

    if (!paused) {
      setTimerDelay(null);
      clearInterval(timer.current);
      timer.current = null;
    } else {
      setTimerDelay(tickIntervalInMs);
      let newStartTime = new Date().getTime();
      timer.current = (setInterval(() => {
        timerTick(newStartTime, timeRemaining);
      }, tickIntervalInMs));
    }
    setPaused(!paused);
  }

  const handleCorrect = () => {
    setScore(score + 1);
    nextSlide();
    playCorrect();
  }

  const handlePass = () => {
    setSlidesRemaining(slidesRemaining - 1);
    nextSlide();
    playPass();
  }

  // const handleChangeDuration = (value: number) => {
  //   setDuration(value);
  //   setTimeRemaining(value);
  // }

  const handleResetGame = () => {
    clearInterval(timer.current);
    timer.current = null;
    setGamePlaying(false);
    setPaused(false);
    setTimeRemaining(duration);
    setScore(0);
    setCurrentSlide(0);
    setGameFinished(false);
    deck.current.slide(0, 0, 0);
    setSlidesRemaining(slideTexts.length);

    if (gameType === 'word') {
      setupWordGame();
    }
    if (gameType === 'qa') {
      setupQaGame();
    }
  }

  const handleCloseEditWords = () => {
    //let newWords = tempWords.split('\n');
    //gameObject.wordSets[parseInt(selectedWordSubSet)] = newWords;
    //setSlideTexts(newWords);
    //setWordsRemaining(newWords.length);
    //handleResetGame();
    //setShowEditWords(false);
  }

  const handleOpenEditWords = () => {
    //setTempWords(gameObject.wordSets[parseInt(selectedWordSubSet)].join('\n'));
    //setShowEditWords(true);
  }


  // const randomizeQaSets = () => {
  //   setQASets(qaSets.sort(() => Math.random() - 0.5));
  // }

  //============================================================== View and Panels ===============================

  const createTitleSlide = () => {
    return (
      <section>
        <h1>{gameObject.name}</h1>
        {/* <h2>{"Set " + (parseInt(selectedWordSubSet) + 1)}</h2> */}
        <h2>Press Start to begin</h2>
      </section>
    )
  }

  const createQASlideshow = () => {
    //let qaSets = qAGame.questionAnswerSets as QuestionAnswer[];
    let qaSets = slideTexts as QuestionAnswer[];
    return (
      qaSets.map((qaSet, index) => {
        return (
          <React.Fragment key={"slideSection" + index}>
            <section data-transition='zoom'>
              {!showAnswers && <h2><span style={{ color: 'red' }}>Question:</span> {qaSet.question}</h2>}
              {showAnswers &&
                qaSet.answers.map((answer, index) => {
                  return <h2 key={"answer" + index}><span style={{ color: 'red' }}>Answer:</span> {answer}</h2>
                }
                )}
            </section>
            {howAboutYou && <section>
              <h2>How about you?</h2>
            </section>}
          </React.Fragment>
        )
      })
    )
  }

  const createWordSetSlideshow = () => {
    return (
      slideTexts.map((word, index) => {
        return (
          <section key={index} data-transition='zoom'>
            <div className='r-fit-text'>{word.toString()}</div>
          </section>
        )
      })
    )
  }

  const createSlideshow = () => {
    if (gameType === "qa") {
      return createQASlideshow();
    }
    if (gameType === "word") {
      return createWordSetSlideshow();
    }
  }

  const returnRevealStyle = () => {
    let y = document.getElementById("Slide-start-marker")?.offsetTop;
    if (y === undefined) {
      y = 0;
    }

    let goldenHeight;
    if (viewWidth > viewHeight) {
      goldenHeight = viewHeight * 0.618;
    } else {
      goldenHeight = viewWidth * 0.618;
    }

    //return { width: viewWidth + "px", height: (viewHeight - y) + "px", margin: "auto" };
    return { width: viewWidth + "px", height: goldenHeight + "px", margin: "auto" };
  }

  const handleChangeHowAboutYou = () => {
    if (!howAboutYou) {
      //Add a howabout you slide after ever slide
      //This only applies to qa games
      let newSlideTexts = [] as QuestionAnswer[];
      let howAboutYouSlide = new QuestionAnswer("How about you?", ["How about you?"]);
      let qaSets = qAGame.qaSets as QuestionAnswer[];
      qaSets.forEach((qaSet) => {
        newSlideTexts.push(qaSet);
        newSlideTexts.push(howAboutYouSlide);
      });
      setSlideTexts(newSlideTexts);
      setSlidesRemaining(newSlideTexts.length);
    } else {
      setSlideTexts(qAGame.qaSets as QuestionAnswer[]);
      setSlidesRemaining(qAGame.qaSets.length);
    }
    setHowAboutYou(!howAboutYou);
  }

  const setupWordGame = () => {
    let words = getWordsFromSubsets(selectedWordSubsets);
    if (randomizeSlides) {
      words = words.sort(() => Math.random() - 0.5);
    }
    setSlidesRemaining(words.length);
    setSlideTexts(words);
  }

  const setupQaGame = () => {
    let qaSets = qAGame.qaSets as QuestionAnswer[];
    if (randomizeSlides) {
      qaSets = qaSets.sort(() => Math.random() - 0.5);
    }
    setSlidesRemaining(qaSets.length);
    setSlideTexts(qaSets);
  }

  const handleCloseOptionsModal = () => {
    setShowOptions(false);
    if (gameType === "word") {
      setupWordGame();
    }
    if (gameType === "qa") {
      setupQaGame();
    }
  }

  const getCurrentQuestionOrAnswers = () => {
    let qaSets = slideTexts as QuestionAnswer[];
    if (showAnswers && tempShowQA) {
      return qaSets[currentSlide - 1].question;
    } else {
      let answers = qaSets[currentSlide - 1].answers.join('\n');
      return answers;
    }
  }

  return (
    <div>
      <Container>
        <div className="text-center my-3 text-3xl md:text-4xl lg:text-6xl">{gameObject.name}</div>
        <div className='grid grid-cols-3'>
          <div className="text-center text-4xl md:text-6xl lg:text-8xl">{score + '/' + slidesRemaining}</div>
          {!gamePlaying &&
            <div>
              <div className=''>
                <div className='flex items-center justify-center'>
                  <Label htmlFor="default-range" className='text-xl md:text-3xl' value={"Time: " + durationLabelLookup(durationSetting)} />
                </div>
                <RangeSlider id="default-range" sizing='lg' min="0" max="5" value={"" + durationSetting}
                  onChange={(e) => {
                    setDurationSetting(parseInt(e.target.value));
                    setDuration(durationLookup(parseInt(e.target.value)));
                  }} />
              </div>

            </div>}
          {!gamePlaying &&

            <div className="flex justify-center items-center">
              <Button size='xs' color='warning' onClick={() => setShowOptions(true)}>Options</Button>
            </div>}
        </div>

        <div className='flex justify-center'>
          {!gamePlaying && !gameFinished &&
            <button className="btn-ts btn-blue" onClick={() => handlePreview()}> {!isPreview ? 'Preview' : 'End Preview'} </button>
          }

          {!gamePlaying && !gameFinished && !isPreview &&
            <button className='btn-ts btn-blue' onClick={() => startGame()}>Start</button>
          }

          {isPreview && <React.Fragment>
            <button disabled={currentSlide == 0} className='btn-ts btn-blue' onClick={() => previewChangeSlide(false)}>Previous</button>
            <button disabled={currentSlide == slideTexts.length} className='btn-ts btn-blue' onClick={() => previewChangeSlide(true)}>Next</button>
          </React.Fragment>}

          {!isPreview && gamePlaying && <React.Fragment>
            <button className='btn-ts btn-blue' onClick={() => handleResetGame()}>Reset</button>
            <button className='btn-ts btn-blue' onClick={() => handlePauseGame()}>{!paused ? 'Pause' : 'Continue'}</button>
            <button className='btn-ts btn-red' onClick={() => handlePass()}>Pass</button>
            <button className='btn-ts btn-green' onClick={() => handleCorrect()}>Correct!</button>
          </React.Fragment>}
          {gameFinished && <React.Fragment>
            <button className='btn-ts btn-blue' onClick={() => handleResetGame()}>Reset</button>
          </React.Fragment>}
        </div>

        <div id="Slide-start-marker">
        </div>
        {gamePlaying && <Counter duration={duration} timeRemaining={timeRemaining} timerDelay={timerDelay} paused={paused}></Counter>}
      </Container>
      <div className="reveal" style={returnRevealStyle()}>
        <div className="slides" data-transition="Slide">
          {createTitleSlide()}
          {createSlideshow()}
        </div>
      </div>

      {gamePlaying && gameType == 'qa' && <div className='my-6 flex items-center'>
        {!tempShowQA && <Button className='m-auto' size='xl' color='warning' onClick={() => setTempShowQA(true)}>{showAnswers ? 'See Question' : 'See Answers'}</Button>}
        {tempShowQA && <div className='text-2xl md:text-8xl m-auto'>{showAnswers ? 'Question: ' + getCurrentQuestionOrAnswers() : 'Answers: ' + getCurrentQuestionOrAnswers()}</div>}

      </div>}

      {/* ======================================    Modal for editing words */}

      {/* <Modal show={showEditWords} onClose={() => setShowEditWords(false)}>
        <Modal.Header>Edit words</Modal.Header>
        <Modal.Body>
          <select value={selectedWordSubSetIndex} onChange={(e) => {
            setSelectedWordSubsetIndex(parseInt(e.target.value));
            //setTempWords(wordGame.wordSets[parseInt(e.target.value)].join('\n'))
          }}
          >
            {wordGame.wordSets && wordGame.wordSets.map((set: any, i: number) => {
              return <option key={"selectWordset" + i} value={i}>{"Set " + (i + 1)}</option>
            })}

          </select>

          <Textarea rows={10}
            value={wordGame.wordSets[selectedWordSubSetIndex] ? wordGame.wordSets[selectedWordSubSetIndex].join('\n') : ''}
            //onChange={(e) => setTempWords(e.target.value)}
            onChange={(e) => {
              let newWordGame = { ...wordGame };
              wordGame.wordSets[selectedWordSubSetIndex] = e.target.value.split('\n');
              setWordGame(newWordGame);
            }}
          ></Textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleCloseEditWords()}>Save</Button>
        </Modal.Footer>
      </Modal> */}

      {/* ======================================    Options */}

      <Modal show={showOptions} onClose={() => handleCloseOptionsModal()}>
        <Modal.Header>Options</Modal.Header>
        <Modal.Body>
          <div>
            {gameType === "qa" &&
              <div>
                <ToggleSwitch checked={showAnswers} label={showAnswers ? 'Showing answers' : 'Showing questions'} onChange={() => setShowAnswers(!showAnswers)} />
                <CheckboxAndLabel label="Add 'How about you?'" checked={howAboutYou} onChange={() => handleChangeHowAboutYou()}></CheckboxAndLabel>
                <CheckboxAndLabel label="Randomize" checked={randomizeSlides} onChange={(e: any) => setRandomizeSlides(!randomizeSlides)}></CheckboxAndLabel>
              </div>}
            {gameType === "word" &&
              <div>
                <CheckboxAndLabel label="Randomize order of slides?" checked={randomizeSlides} onChange={(e: any) => setRandomizeSlides(!randomizeSlides)}></CheckboxAndLabel>
                <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                {wordGame.wordSets.map((set, i) => {
                  return <CheckboxAndLabel
                    key={"wordset" + i}
                    label={"Set " + (i + 1)}
                    checked={selectedWordSubsets.includes(i)}
                    onChange={() => {
                      if (selectedWordSubsets.includes(i)) {
                        let newSelectedWordSubSets = selectedWordSubsets.filter((x) => x !== i);
                        updateWordGame(newSelectedWordSubSets);
                      } else {
                        let newSelectedWordSubSets = [...selectedWordSubsets, i];
                        updateWordGame(newSelectedWordSubSets);
                      }
                    }
                    }></CheckboxAndLabel>
                }
                )}
                {/* <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr> */}
                {/* <Button size='xs' color='warning' onClick={() => handleOpenEditWords()}>Edit wordsets</Button> */}
              </div>}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleCloseOptionsModal()}>Close</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );

}

export default RunFlashGame;