import { Editor } from '@tiptap/react';

type Props = {
    editor: Editor;
}

const TiptapPanelControls = (props: Props) => {
    let editor = props.editor;

    if (!editor) {
        return null
    }

    const defaultClass = "bg bg-blue-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2 text-sm";

    const returnButtonClassName = (isActive: Boolean) => {
        return "bg " + (isActive ? "bg-col-p2" : "bg-col-s2") + " hover:bg-col-s1 text-white font-bold py-2 px-4 rounded m-2 textsm";
    }

    return (

        <div className="flex-auto">
            {/* ================================= Undo / Redo */}
            <button
                onClick={() => editor.chain().focus().undo().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .undo()
                        .run()
                }
                className={defaultClass}

            >
                Undo
            </button>
            <button
                onClick={() => editor.chain().focus().redo().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .redo()
                        .run()
                }
                className={defaultClass}
            >
                Redo
            </button>
            {/* ================================= Controls */}
            <button
                onClick={() => editor.chain().focus().clearNodes().run()}
                className={defaultClass}
            >
                Clear nodes
            </button>
            <button
                onClick={() => editor.chain().focus().deleteCurrentNode().run()}
                className={defaultClass}
            >
                Delete node
            </button>
           
            {/* ================================= Alignment */}
            <button
                onClick={() => editor.chain().focus().setTextAlign('left').run()}
                className={returnButtonClassName(editor.isActive({ textAlign: 'left' }))}
            >
                Left
            </button>
            <button
                onClick={() => editor.chain().focus().setTextAlign('center').run()}
                className={returnButtonClassName(editor.isActive({ textAlign: 'center' }))}

            >
                Center
            </button>
            <button
                onClick={() => editor.chain().focus().setTextAlign('right').run()}
                className={returnButtonClassName(editor.isActive({ textAlign: 'right' }))}

            >
                Right
            </button>
            <button
                onClick={() => editor.chain().focus().setTextAlign('justify').run()}
                className={returnButtonClassName(editor.isActive({ textAlign: 'justify' }))}

            >
                Justify
            </button>
            {/* <button
                onClick={() => editor.chain().focus().unsetTextAlign().run()}
                className={returnButtonClassName(editor.isActive({ textAlign: null }))}
            >Unset text align</button> */}
        </div>

    )
}
export default TiptapPanelControls;  