function createGUID(length){
    
    if (length === undefined) {length = 5}
    // let u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
    // let guid = [u.substr(0, 8), u.substr(8, 4), '4000-8' + u.substr(13, 3), u.substr(16, 12)].join('-');
    // guid = guid.substring(7,12);
    //console.log(guid);

    function randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }
    var guid = randomString(length, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

    return guid;

    // let objectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
    //     s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))

    // return objectId;
    
}

export default createGUID;