//React
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Components 

//Services/Helpers
//import apiService from "services/apiService";
import crudService from "services/crudService";
import errorService from "services/errorService";
import config from "config";
import { FilterModel, PropertyFilter } from "classes/models/request/FilterModel";
import { Class } from "classes/enums/Class";

//Store

//Views/Components

//Classes

const StageFlashGame = () => {

    const { type, id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {

        const getWordGame = async () => {
            let filter = new FilterModel([new PropertyFilter("Id", id)]);
            let response = await crudService.load(Class.wordSetGroup, filter);
            if (response.success) {
                setIsLoading(false);
                navigate('/wordflash/run', { state: { gameType: type, gameObject: response.payload }, replace: true });
            } else {
                errorService.handleError(response);
            }
        }

        const getQAGame = async () => {
            let filter = new FilterModel([new PropertyFilter("Id", id)]);
            let response = await crudService.load(Class.qASetGroup, filter);
            if (response.success) {
                setIsLoading(false);
                navigate('/wordflash/run', { state: { gameType: type, gameObject: response.payload }, replace: true });
            } else {
                errorService.handleError(response);
            }
        }

        if (type === undefined || (type !== 'word' && type !== 'qa')) {
            console.log('StageFlashGame: invalid type:', type);
            navigate(-1);
        } else {
            if (type === 'word') {
                getWordGame();
            } else if (type === 'qa') {
                getQAGame();
            }
        }

        console.log('StageFlashGame: type:', type, 'id:', id);
    }, [type, id]);

    return (
        <div className='container mx-auto'>
            <div className='my-auto mx-auto'>
                <h1 className='text-3xl mt-8 text-center'>Loading</h1>
                <img className='animate-spin m-auto w-[32] h-[32]' src={require('assets/images/synapp.png')}></img>
            </div>
        </div>
    );
}

export default StageFlashGame;