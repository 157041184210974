//React
import { useEffect, useState } from 'react';

//UI
import { Button, Modal, ToggleSwitch } from "flowbite-react";

//Services
import crudService from 'services/crudService';
import errorService from 'services/errorService';
import { toast } from 'react-toastify';
import { useAtom } from 'jotai';
import { categoriesAtom } from "atom";

//Logics

//Components

//Classes
import { Category } from 'classes/synapp/Category';
import { FilterModel, PropertyFilter } from "classes/models/request/FilterModel";
import { Class } from 'classes/enums/Class';

type Props = {
    categoryIds: string[];
    setCategoryIds: Function;
    editable: boolean;
    //lockedLevels: number;
}

const CategoryManager = (props: Props) => {

    const { setCategoryIds, categoryIds, editable } = props;
    const [categories, setCategories] = useAtom(categoriesAtom);
    const [newCategoryNames, setNewCategoryNames] = useState<string>("");
    const [showCreateCategoryModal, setShowCreateCategoryModal] = useState<boolean>(false);
    const [editingOn, setEditingOn] = useState(false);

    useEffect(() => {
        //Add None category
        if (categories.length > 0 && categories[0].name !== "None") {
            let rootCategory = new Category();
            rootCategory.id = "None";
            rootCategory.name = "None";
            rootCategory.level = 0;
            rootCategory.parentId = "None";

            let tempCategories = [rootCategory, ...categories];
            setCategories(tempCategories);
        }

    }, []);

    const deleteCategory = async () => {

        let parentId = categoryIds.length > 0 ? categoryIds[categoryIds.length - 1] : "None";
        if (parentId === "None") {
            return;
        }
        let categoryId = categories.find(x => x.id === parentId)?.parentId;
        if (categoryId === "None" || categoryId === undefined) {
            return;
        }

        let filterModel = new FilterModel([new PropertyFilter("Id", categoryId)]);
        let response = await crudService.del(Class.category, filterModel);
        if (response.success) {
            let tempCategories = [...categories];
            let index = tempCategories.findIndex(x => x.id === categoryId);
            tempCategories.splice(index, 1);
            setCategories(tempCategories);
            //TODO: remove selectedCategory from state
            toast.success("Category deleted");
        } else {
            errorService.handleError(response);
        }
    }

    const createUpdateCategory = async () => {
        let newCategoryLevel = categoryIds.length;
        let newCategoryParentId = (categoryIds.length > 0 || categoryIds[0] === "None") ? categoryIds[categoryIds.length - 1] : "None";

        let newCategories = [];
        let categoryNames = newCategoryNames.split(";");
        //remove empty strings
        categoryNames = categoryNames.filter((name) => name.trim() !== "");
        //Todo: check if category already exists

        for (let name of categoryNames) {
            let thisCategory = new Category();
            thisCategory.name = name.trim();
            thisCategory.parentId = newCategoryParentId;
            thisCategory.level = newCategoryLevel;
            newCategories.push(thisCategory);
        }

        let requestModel = newCategories;

        let response = await crudService.create(Class.category, requestModel);
        if (response.success) {

            let responseModel = response.payload as Category[];
            let tempCategories = [...categories];
            //TODO: Only creating new categories, not updating existing ones
            for (let category of responseModel) {
                tempCategories.push(category);
            }
            setCategories(tempCategories);
            toast.success("Category created");

        } else {
            errorService.handleError(response);
        }
    }

    const returnCategoryClass = (level: number, category: Category) => {
        let className = "p-2 m-2 cursor-pointer border border-4 rounded text-sm";
        if (categoryIds.length > level) {
            if (categoryIds[level] === category.id) {
                className += " bg-blue-200 border-blue-700 ";
            } else {
                className += " bg-gray-200 border-gray-700 ";
            }
        }
        return className;
    }

    const updateCategoryIds = (level: number, category: Category) => {
        let newCategoryIds = [...categoryIds];
        if (level === 0) {
            newCategoryIds = [category.id];
        } else {
            newCategoryIds = newCategoryIds.slice(0, level).concat([category.id]);
        }
        setCategoryIds(newCategoryIds);
    }

    //Creation modal
    const createCategoryModal = () => {
        return (<Modal show={showCreateCategoryModal} size="md" onClose={() => setShowCreateCategoryModal(false)} popup>
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    Seperate name with semicolon for multiple categories:
                    <input className="w-full" type="text" placeholder='Category names here...' value={newCategoryNames} onChange={(e) => { setNewCategoryNames(e.target.value) }} />
                    <Button onClick={() => createUpdateCategory()}>Save new category</Button>
                    {/* <Button onClick={() => deleteCategory()}>Delete category</Button> */}
                </div>
            </Modal.Body>
        </Modal>);
    }

    return (
        <div>
            {editable && <div className='relative'>
                <ToggleSwitch color='gray' className='absolute right-2' checked={editingOn} label={editingOn ? 'Editing on' : 'Editing off'} onChange={() => setEditingOn(!editingOn)} />
            </div>}
            {/* //Selected categories */}
            <div className="flex">
                {categoryIds.map((categoryId, index) => {
                    let thisCategory = categories.find(x => x.id === categoryId);
                    return (
                        <div key={"selected-" + index} className="flex flex-wrap">
                            <div
                                className="p-2 m-2 cursor-pointer border border-4 rounded bg-blue-200 border-blue-700"

                                onClick={() => setCategoryIds(categoryIds.slice(0, index + 1))}>
                                {/* <span className="text-slate-300 text-xs">Level {index + 1}</span> */}
                                <div className="text-sm">{thisCategory?.name}</div>

                            </div>
                            {categories.filter(x => x.level === categoryIds.length).length > 0 && <div className="grid content-center">{"=>"}</div>}
                        </div>
                    )
                })}


            </div>

            {/* //Select categories */}
            <div className="flex flex-wrap">
                {categories.filter((category) => category.level === categoryIds.length && category.parentId === (categoryIds.length > 0 ? categoryIds[categoryIds.length - 1] : "None")).map((thisCategory, index) => {
                    return (
                        <div
                            key={"level-select-" + index}
                            className={returnCategoryClass(categoryIds.length, thisCategory)}
                            onClick={() => updateCategoryIds(categoryIds.length, thisCategory)}>{thisCategory.name}</div>
                    )
                })}

            </div>
            
            {editable && editingOn && <Button onClick={() => setShowCreateCategoryModal(true)}>Create new category</Button>}

            {editable && showCreateCategoryModal && createCategoryModal()}
        </div>
    );
}

export default CategoryManager;