//React

//UI

//Services

//Logics

//Classes

//Components



import { useAtom } from 'jotai';
import { Button } from 'flowbite-react';
import { courseProgressAtom, courseAtom } from 'atom';
import { PageCheckpointProgress } from "classes/courseprogress/PageCheckpointProgress";
import { CheckpointTypes } from 'classes/enums/CheckpointTypes';
import crudService from 'services/crudService';
import { Class } from "classes/enums/Class";
import { toast } from 'react-toastify';
import { CourseChallenge } from 'classes/course/CourseChallenge';

type Props = {
    elementId: string;
    courseChallenge: CourseChallenge;
}

const CheckpointContinueElement = (props: Props) => {
    const { elementId, courseChallenge } = props;
    const [courseProgress, setCourseProgress] = useAtom(courseProgressAtom);
    const [nodeCourse] = useAtom(courseAtom);

    const checkpointIsComplete = (type: string) => {
        let challengeProgress = courseProgress.challengeProgresses.find((x) => x.challengeId === courseChallenge.id);
        if (challengeProgress && challengeProgress.checkpointProgresses.find((x) => x.elementId === elementId && x.type === type)) {
            return true;
        };
        return false;
    }

    const completeCheckpoint = async () => {
        let nodePageCheckpointProgress = new PageCheckpointProgress();
        nodePageCheckpointProgress.elementId = elementId;
        nodePageCheckpointProgress.type = CheckpointTypes.Continue;
        nodePageCheckpointProgress.pointsAwarded = 1;
        nodePageCheckpointProgress.dateCompleted = Date.now();
        nodePageCheckpointProgress.isCompleted = true;

        let updatedCourseProgress = { ...courseProgress };
        let challengeProgress = updatedCourseProgress.challengeProgresses.find((x) => x.challengeId === courseChallenge.id);

        if (!challengeProgress) {
            console.error("Challenge progress not found for course text continue checkpoint");
            console.log(updatedCourseProgress);
            return;
        }

        challengeProgress.checkpointProgresses.push(nodePageCheckpointProgress);

        //If all checkpoints are complete, mark the challenge as complete
        let thisChallenge = nodeCourse.courseChallenges.find((courseChallenge) => courseChallenge.id === courseChallenge.id);
        if (thisChallenge && thisChallenge.checkpoints.length === challengeProgress.checkpointProgresses.length) {
            challengeProgress.isComplete = true;
            challengeProgress.dateCompleted = Date.now();
        }

        crudService.update(Class.courseProgress, updatedCourseProgress).then((response) => {
            if (response.success) {
                updatedCourseProgress.visibilityUpdated = true;
                setCourseProgress({ ...updatedCourseProgress });
                toast.success("Checkpoint completed successfully!");
            }
            else {
                toast.error("Error updating checkpoint!");
            }
        });

    }

    return (
        <>
            {!checkpointIsComplete(CheckpointTypes.Continue) &&
                <div className="border border-col-p2 border-4 m-2 flex items-center justify-center">
                    <div className="text-lg md:text-2xl font-black mx-2 md:mx-4">Continue?</div>
                    <p className="mr-2 text-center">Click continue to unlock this checkpoint</p>
                    <Button className="mx-2 md:mx-4" onClick={() => {
                        completeCheckpoint();
                    }}>Continue</Button>

                </div>}
            {checkpointIsComplete(CheckpointTypes.Continue) &&
                <div className="border border-col-p2 border-4 m-2 flex items-center justify-center">
                    <div className="text-lg md:text-2xl font-black mx-2 md:mx-4">Checkpoint Complete!</div>
                </div>}
        </>
    )

}

export default CheckpointContinueElement;


