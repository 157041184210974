//React
import { useState, useEffect } from 'react';

//UI
import { Button } from "flowbite-react";
import "css/prism-vsc-dark.css";
import 'css/codeeditor.css';

//Services

import createGUID from 'logic/utility/createGUID';

//Logics
import codeEditorLogic from "logic/gridslate/codeEditor/codeEditorLogic";
import parseCodeEditor from "logic/parse/parseCodeEditor";

//Components
import Editor from 'react-simple-code-editor';

//Classes
import { CodeError } from 'classes/synapp/code_exercise/CodeError';

type Props = {
    code: string;
}

const TryItCodeblockElement = (props: Props) => {
    const { code } = props;
    const [thisCode, setThisCode] = useState(code);
    const [codeError, setCodeError] = useState<CodeError>(new CodeError());

    let codeId = createGUID(10);

    useEffect(() => {
        setCodeError(new CodeError());
    }, [thisCode]);


    useEffect(() => {

        
        window.onmessage = function (e) {
        
            if (typeof (e.data) == "string") {
                try {
                    let parsedError = JSON.parse(e.data);
                    //window.top.postMessage("error: "+evt.message +" at linenumber: "+evt.lineno+" of file: "+evt.filename, '*');
                    if (parsedError != null) {
                        setCodeError(parsedError);
                    }
                }
                catch (err) {
                }
            }

        };

        //TODO: Check if this is the right way to remove the event listener
        return () => {
            window.removeEventListener("message", () => { });
        }

    });

    const handleRunCodeClick = () => {
        if (codeError.lineNumber !== "") { setCodeError(new CodeError()); }

        //let content = sourceCode;
        var iframe = document.getElementById('targetCode'+ codeId) as HTMLIFrameElement;
        if (!iframe) { return; }

        if (iframe.contentDocument) {
            iframe.contentDocument.open();
            iframe.contentDocument.write(codeEditorLogic.getHead("thisCode") + thisCode + codeEditorLogic.getTail());
            iframe.contentDocument.close();
        }

        return false;
    }

    return (
        <div className="w-full mt-4 min-h-80 grid grid-cols-1 md:grid-cols-2 bg-[#1F1F1F] rounded">
            <div className="border border-col-p2 border-4 m-2 p-2">
                <Button className="float-right text-xs" onClick={() => { handleRunCodeClick() }} >Run Code</Button>
                <strong className="text-white">Code</strong>
                {codeError.lineNumber !== "" && <div className="text-red-500">Error on line {codeError.lineNumber}: {codeError.message}</div>}
                <div className="code-editor-div text-black">

                    <Editor
                        value={thisCode}
                        padding={10}
                        className="code-editor"
                        onValueChange={(text: string) => setThisCode(text)}
                        highlight={(sourceCode) => parseCodeEditor.parseCodeLines(sourceCode, "thisCode", codeError)}
                        textareaId="codeArea"
                    />
                </div>
            </div>

            <div className="border border-col-p2 border-4 m-2 p-2">
                <strong className="text-white">Output</strong>
                <iframe 
                style={{
                    width: "100%",
                    height: "90%",
                }}
                    name="targetCode"
                    title="Code Output"
                    id={"targetCode" + codeId}>
                </iframe>
            </div>
        </div>

    );
}

export default TryItCodeblockElement;