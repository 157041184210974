//REDACT??

//React
//import React from 'react';
import { useState, useEffect } from 'react';

//Components 
//import Editor from "react-simple-code-editor";
import Dropzone from 'react-dropzone';

//Services/Helpers
import createGUID from 'logic/utility/createGUID';

//Store

//Views/Components
import { Container } from "view_components/helper/HelperComponents";
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Color } from '@tiptap/extension-color';
import Image from '@tiptap/extension-image';
import TextStyle from '@tiptap/extension-text-style';

//import History from '@tiptap/extension-history';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';
import Highlight from '@tiptap/extension-highlight';

import UniqueID from '@tiptap-pro/extension-unique-id'
import DragHandle from '@tiptap-pro/extension-drag-handle-react'
import FileHandler from '@tiptap-pro/extension-file-handler'

import TiptapToolbar from "views/courseconstruction/tiptappanels/TiptapToolbar";
import TiptapPanelMarks from "views/courseconstruction/tiptappanels/TiptapPanelMarks";

//Classes
import { Mcq } from "classes/synapp/mcq/Mcq";
//CSS
//import "css/tiptap.css";
//import "css/codeeditor.css";
import "css/custom-tiptap-styles.css";
import "css/prosemirror.css";


//React

//Services
import crudService from 'services/crudService';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';
import docXService from 'services/docXService';
import apiService from 'services/apiService';
import config from 'config';
import { useAtom } from 'jotai';
import { categoriesAtom } from "atom";
import { loadedMcqs } from "atom";
import {baseCategoryIdsAtom} from "atom";

//Logics
//import categoryStatusLogic from 'logic/gridslate/categoryStatusLogic';
import parseLogic from 'logic/parse/parseLogic';
import genericSaveLoadLogic from 'logic/saveload/genericSaveLoadLogic';

//Components
import GenericSaveLoadPanel from "views/utility/GenericSaveLoadPanel";
import CheckpointExtension from "views/courseconstruction/customelements/CheckpointExtension";
import CategoryManager from "views/courseconstruction/CategoryManager";

//Classes
import { FilterModel } from "classes/models/request/FilterModel";
import { Class } from 'classes/enums/Class';
import { Status } from 'classes/enums/Status';
import { RequestImageUploadURLResponseModel } from "classes/models/response/RequestImageUploadURLResponseModel";

import { Page, getPageProperties } from "classes/Page";
import { CrudAction } from 'classes/enums/CrudAction';
import { EditablePropertyMetadata } from 'classes/gridslate/EditablePropertyMetadata';

import { IsBusy } from 'classes/general/IsBusy';

const defaultContent = `
<h2>
  Hi there,
</h2>
<p>
  this is a <em>basic</em> example of <strong>Tiptap</strong>. Sure, there are all kind of basic text styles you’d probably expect from a text editor. But wait until you see the lists:
</p>
`

const PageConstructor = () => {

  const [nodePages, setPages] = useState<Page[]>([]);
  const [mcqs, setMcqs] = useAtom(loadedMcqs);
 
  const [loadedPageIndex, setLoadedPageIndex] = useState<number>(-1);
  //const [nodePageComponentDataList, setPageComponentDataList] = useState<PageComponentData[]>([]);
  const [isBusy, setIsBusy] = useState(new IsBusy());
  const [categories, setCategories] = useAtom(categoriesAtom);
  const [baseCategoryIds, setBaseCategoryIds] = useAtom(baseCategoryIdsAtom);

  //const mainTabsRef = useRef<TabsRef>(null);

  useEffect(() => {

    const loadFoos = () => {
      setIsBusy(isBusy.loading(["nodePages"]));
      let filterModel = new FilterModel();
      filterModel.onlyOwner = true;
      crudService.get(Class.page, filterModel).then(response => {
        if (response.success) {
          setPages(response.payload);
        } else {
          errorService.handleError(response);
        }
        setIsBusy(isBusy.loaded("nodePages"));
      });
    }

    if (nodePages.length === 0) {
      loadFoos();
    }

  }, []);

  useEffect(() => {
    //TODO: load mcqs
    const loadMcqs = async () => {
      let filterModel = new FilterModel();

      let response = await crudService.get(Class.mcq, filterModel);
      if (response.success) {
        let responseModel = response.payload as Mcq[];
        for (let i = 0; i < responseModel.length; i++) {
          responseModel[i].status = Status.unchanged;
        }
        setMcqs(response.payload);
      } else {
        errorService.handleError(response);
      }

    }
    loadMcqs();
  }, []);

  useEffect(() => {
    const loadCategories = async () => {
      let filterModel = new FilterModel();
      crudService.get(Class.category, filterModel).then(response => {
        if (response.success) {
          setCategories(response.payload);
        } else {
          errorService.handleError(response);
        }

      });
    }

    if (categories.length === 0) {
      loadCategories();
    }

  }, []);

  const extensions = [
    StarterKit,
    Image,
    TextStyle,
    Link.configure({
      openOnClick: false,
    }),
    Color.configure({
      types: ['textStyle', 'heading', 'paragraph'],
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph', 'blockquote'],
    }),
    Highlight.configure({ multicolor: true }),
    FileHandler.configure({
      allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
      onDrop: (currentEditor, files, pos) => {
        files.forEach(file => {
          createImageFromFileDrop(currentEditor, file, pos);
          // const fileReader = new FileReader()
          // fileReader.readAsDataURL(file)
          // fileReader.onload = () => {
          //   currentEditor.chain().insertContentAt(pos, {
          //     type: 'image',
          //     attrs: {
          //       src: fileReader.result,
          //     },
          //   }).focus().run()
          // }
        })
      },
    }),
    CheckpointExtension,
    UniqueID.configure({
      attributeName: 'elementId',
      types: ['heading', 'paragraph', 'codeBlock', 'bulletList', 'orderedList', 'listItem', 'blockquote', 'horizontalRule', 'image'],
      generateID: () => createGUID(10),
    })
  ];

  const createImageFromFileDrop = async (editor: any, file: any, pos: any) => {
    let localSrc = URL.createObjectURL(file);
    let elementId = createGUID(10);

    let response = await apiService.post(config.apiUrl + "/cms/RequestImageUploadURL", { "LocalId": elementId });
    if (response.success) {
      let responseModel = response.payload as RequestImageUploadURLResponseModel;
      var putS3Response = await apiService.put(responseModel.uploadUrl, file)
      if (putS3Response.success) {
        editor.chain().focus().insertContentAt(pos, {
          type: 'image',
          attrs: {
            elementId: elementId,
            src: responseModel.fullPath,
          },
        }).focus().run();
        //editor.commands.set('paragraph', { id: 'paragraph-01' })
      } else {
        console.log("Put to S3 error: ", putS3Response);
        //TODO: Check about using errorService here
      }

    } else {
      errorService.handleError(response);
    }
  }

  //================================================================================================= - Edit Page

  const crudActions = (action: CrudAction, value: any, target?: string) => {
    if (action === CrudAction.appendNewObject) {
      //expect value as new code challenge object
      var newFoos = [...nodePages, value];
      setLoadedPageIndex(newFoos.length - 1);
      setPages(newFoos);
    }
    if (action === CrudAction.getUpdatedObject) {
      let updatedObject = nodePages[loadedPageIndex];
      if (editor) {
        updatedObject.componentJSON = JSON.stringify(editor.getJSON());
      } else {
        console.error("Editor not loaded on save attempt");
      }
      return updatedObject;
      //updatedObject.componentJSON = editor.getJSON().toString();
      //return nodePages[loadedPageIndex];
    }
    else if (action === CrudAction.returnNewObject) {
      let newFoo = new Page();
      return newFoo;
    }
    else if (action === CrudAction.loadObject) {
      //value is actually id of object here
      //console.log(value);
      let index = nodePages.findIndex((nodePage) => nodePage.id === value);
      if (editor) {
        editor.commands.setContent(JSON.parse(nodePages[index].componentJSON));
        //setPageComponentDataList(nodePages[index].componentData);
        setBaseCategoryIds(nodePages[index].categoryIds);
      } else {
        console.error("Editor not loaded on load attempt");
      }

      setLoadedPageIndex(index);
    }
    else if (action === CrudAction.updateObject) {
      //value as Page object
      let newFoo = value as Page;
      var tempFoos = [...nodePages];
      tempFoos[loadedPageIndex] = newFoo;
      setPages(tempFoos);
      toast.success("Code Exercise Updated");
    }
    else if (action === CrudAction.updateObjectProperties) {
      let editableProperties = value as EditablePropertyMetadata[];
      let newFoos = [...nodePages];
      let newFoo = { ...newFoos[loadedPageIndex] };
      newFoo = parseLogic.parseObjectUpdate(newFoo, editableProperties);
      newFoos[loadedPageIndex] = newFoo;
      setPages(newFoos);
    }

    else if (action === CrudAction.deleteObject) {
      //value is actually the object here
      //But SHOULD always be currently selected object, especially if disabling selection while pending
      var tempFoos = [...nodePages];
      //find index of object
      let index = tempFoos.findIndex((nodePage) => nodePage.id === value.id);
      tempFoos.splice(index, 1);
      setPages(tempFoos);
      setLoadedPageIndex(-1);
      toast.success("Code Exercise Deleted");
    }

  }

  const editor = useEditor({
    extensions: extensions,
    content: defaultContent,
    editorProps: {
      attributes: {
        //class: 'prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl m-5 focus:outline-none',
        class: 'max-w-none prose prose-sm sm:prose-base lg:prose-base xl:prose-xl'
      },
    },
  })

  if (!editor) {
    return null;
  }

  const handleDocXUpload = async (files: any) => {
    let data = await docXService.handleDocXUpload2(files);
    if (data && data.success) {
      let parsedPageJSON = docXService.createPageFromDocXData(data.payload) as any;
      editor.commands.setContent(parsedPageJSON);
    } else {
      errorService.handleError(data);
    }

  }

  const setCategoryIds = (categoryIds: string[]) => {
    let newFoos = [...nodePages];
    let newFoo = { ...newFoos[loadedPageIndex] };
    newFoo.categoryIds = categoryIds;
    newFoos[loadedPageIndex] = newFoo;
    setPages(newFoos);
    setBaseCategoryIds(categoryIds);
  }

  const getHeadings = () => {
    let levelToFind = 3;
    let headings: string[] = [];
    if (editor) {
      let json = editor.getJSON();
      if (!json.content) {
        return;
      }
      json.content.forEach((element: any) => {
        if (element.type === "heading" && element.attrs.level === levelToFind) {
          headings.push(element.content[0].text);
        }
      });
    }
    let headingsString = headings.join(";");
    navigator.clipboard.writeText(headingsString);

    //console.log(headings);
    //return headings;
  }

  return (
    <Container>
      <GenericSaveLoadPanel
        objectType='nodePage'
        loadedObjectIndex={loadedPageIndex}
        objects={nodePages}
        crudActions={crudActions}
        saveLoadData={nodePages[loadedPageIndex]}
        getEditableProperties={() => { return getPageProperties(nodePages[loadedPageIndex]) }}
        saveLoadLogic={genericSaveLoadLogic}
      />
      {loadedPageIndex !== -1 && <CategoryManager categoryIds={nodePages[loadedPageIndex].categoryIds} setCategoryIds={setCategoryIds} editable={true} />}
      <button onClick={() => {
        console.log('JSON: ', editor.getJSON())
        getHeadings();
      }}>Log content</button>
      <Dropzone onDrop={(acceptedFiles) => handleDocXUpload(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className="flex w-full items-center justify-center h-32 cursor-pointer">
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          </section>
        )}
      </Dropzone>

      {/* <Button onClick={() => handleDocXUpload(fileToUpload)}>Upload</Button> */}
      <TiptapToolbar editor={editor} />
      <DragHandle editor={editor}>

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="black">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
        </svg>
      </DragHandle>
      <br />
      <EditorContent editor={editor} />

      <BubbleMenu editor={editor}>

        <TiptapPanelMarks editor={editor} bubble={true} />
      </BubbleMenu>
    </Container>
  )
}

export default PageConstructor