import { Editor } from '@tiptap/react';
import TiptapPanelControls from './TiptapPanelControls';
import TiptapPanelTypes from './TiptapPanelTypes';
import TiptapPanelSynappTypes from "./TiptapPanelSynappTypes";

type Props = {
    editor: Editor;
}

const TiptapToolBar = (props: Props) => {
    let editor = props.editor;

    if (!editor) {
        return null
    }


    return (
        <div className='sticky top-5 z-10 bg-white rounded border-col-s1 border-4'>
            <div className="flex-auto">
                <TiptapPanelControls editor={editor} />
                <TiptapPanelTypes editor={editor} />
                <TiptapPanelSynappTypes editor={editor} />

            </div>
        </div>

    )
}
export default TiptapToolBar;  