import { Editor } from '@tiptap/react';

type Props = {
    editor: Editor;
}

const TiptapPanelTypes = (props: Props) => {
    let editor = props.editor;

    if (!editor) {
        return null
    }

    const defaultClass = "bg bg-blue-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2 text-sm";

    const returnButtonClassName = (isActive: Boolean) => {
        return "bg " + (isActive ? "bg-col-p2" : "bg-col-s2") + " hover:bg-col-s1 text-white font-bold py-2 px-4 rounded m-2 text-sm";
    }

    return (

        <div className="flex-auto">
          

            {/* ================================= Types */}
            <button
                onClick={() => editor.chain().focus().setParagraph().run()}
                className={returnButtonClassName(editor.isActive('paragraph'))}
            >
                Paragraph
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={returnButtonClassName(editor.isActive('heading', { level: 1 }))}
            >
                H1
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={returnButtonClassName(editor.isActive('heading', { level: 2 }))}
            >
                H2
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                className={returnButtonClassName(editor.isActive('heading', { level: 3 }))}
            >
                H3
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                className={returnButtonClassName(editor.isActive('heading', { level: 4 }))}
            >
                H4
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                className={returnButtonClassName(editor.isActive('heading', { level: 5 }))}
            >
                H5
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
                className={returnButtonClassName(editor.isActive('heading', { level: 6 }))}
            >
                H6
            </button>
            <button
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={returnButtonClassName(editor.isActive('bulletList'))}
            >
                Bullet list
            </button>
            <button
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={returnButtonClassName(editor.isActive('orderedList'))}
            >
                Ordered list
            </button>
            <button
                onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                className={returnButtonClassName(editor.isActive('codeBlock'))}
            >
                Code block
            </button>
            <button
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={returnButtonClassName(editor.isActive('blockquote'))}
            >
                Blockquote
            </button>
            {/* ================================= Misc */}
            <button
                onClick={() => editor.chain().focus().setHorizontalRule().run()}
                className={defaultClass}
            >
                Horizontal rule
            </button>
            <button
                onClick={() => editor.chain().focus().setHardBreak().run()}
                className={defaultClass}
            >
                Hard break
            </button>

        </div>

    )
}
export default TiptapPanelTypes;  