//React
import React, { useState } from "react";

//UI
import courseIcons from "view_components/icons/courseIcons";
import { Button, Modal, Textarea, Alert } from "flowbite-react";

//Services

//Logics

//Components

//Store

//Classes
import { QASetGroup, QASet } from "classes/synapp/wordflash/QASetGroup";

type QASetGroupModalProps = {
    showModal: boolean,
    setShowModal: Function,
    qAFlashSet: QASetGroup | null,
    handlePersistWordSetGroup: Function
}

const CreateQASetGroupModal = (props: QASetGroupModalProps) => {

    const { showModal, setShowModal, qAFlashSet, handlePersistWordSetGroup } = props;
    const [tempQASetGroup, setTempQASetGroup] = useState<QASetGroup>(qAFlashSet ? qAFlashSet : new QASetGroup('', ''));
    const [showCreateQASetModal, setShowCreateQASetModal] = useState<boolean>(false);
    const [tempQASet, setTempQASet] = useState<QASet>(new QASet('', ['']));
    const [editingQASetIndex, setEditingQASetIndex] = useState(-1);
    const [parsingErrorMessage, setParsingErrorMessage] = useState<string>('');
    //const [isSaving, setIsSaving] = useState<boolean>(false);

    const [showTextToWordsModal, setShowTextToWordsModal] = useState<boolean>(false);
    const [tempWords, setTempWords] = useState<string>('');
    const tempWordsText = "*How are you? \n I'm fine \n I'm sad \n *Do you like baseball? \n Yes, I do. \n No, I don't";

    const parseTextToWords = () => {
        let wordArray = tempWords.split('\n');
        let thisTempQASetList: QASet[] = [];
        let errorParsing = false;
        let errorMessage = '';

        //Clean up the text, remove whitespace and comments
        for (let i = 0; i < wordArray.length; i++) {
            wordArray[i] = wordArray[i].replace('//', '');
            wordArray[i] = wordArray[i].trim();
        }
        //remove empty lines
        wordArray = wordArray.filter((word) => word !== '');

        //console.log(wordArray);

        //parse text to QASet, each new question starts with a *, each new answer starts with a new line after a question
        //an error will be thrown if a question does not have any answers, or if the first line is not a question

        for (let i = 0; i < wordArray.length; i++) {

            let answers: string[] = [];
            if (wordArray[i].charAt(0) === '*') {
                let question = wordArray[i].substring(1);
                i++;
                while (i < wordArray.length && wordArray[i].charAt(0) !== '*') {
                    answers.push(wordArray[i]);
                    i++;
                }
                //Account for the last line of the array
                if (i !== wordArray.length) {
                    i--;
                }
                if (answers.length === 0) {
                    errorParsing = true;
                    errorMessage = 'Question without answers. Each question must have at least one answer. Answers must be on the next line after the question.';
                    break;
                }
                thisTempQASetList.push(new QASet(question, answers));
            } else {
                errorParsing = true;
                errorMessage = 'First line must be a question. Use a star (*) to indicate a question.';
                break;
            }

        }

        if (errorParsing) {
            return errorMessage;
        } else {
            let newQASetGroup = { ...tempQASetGroup };
            //newQASetGroup.qaSets = thisTempQASetList;
            for (let i = 0; i < thisTempQASetList.length; i++) {
                newQASetGroup.qaSets.push(thisTempQASetList[i]);
            }
            setTempQASetGroup(newQASetGroup);
            return 'success';
        }

    }

    const editQASet = (action: string, index: number, value: string) => {
        if (action === 'edit') {
            setEditingQASetIndex(index);
            setTempQASet(tempQASetGroup.qaSets[index]);
            setShowCreateQASetModal(true);
        }
        if (action === 'delete') {
            let newQASetGroup = { ...tempQASetGroup };
            newQASetGroup.qaSets.splice(index, 1);
            setTempQASetGroup(newQASetGroup);
        }
    }

    const QAStub = (props: { qaSet: QASet, index: number }) => {
        return (
            <div className='text-black rounded border-4 border-col-p1 bg-col-t1 m-3 p-1 w-fit inline-block whitespace-pre text-2xl'>
                {props.qaSet.question + "  : " + props.qaSet.answers.join(' / ') + "  "}
                <div className='float-right cursor-pointer border-red-600 border-1 bg-red-200 p-1' onClick={() => editQASet('delete', props.index, '')}>
                    {courseIcons.renderDeleteIcon(16, 16)}
                </div>
                <div className='float-right cursor-pointer border-blue-600 border-1 bg-blue-200' onClick={() => editQASet('edit', props.index, '')}>
                    {courseIcons.renderEditIcon(16, 16)}
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <Modal size="4xl" show={showModal} onClose={() => setShowModal(false)}>
                <Modal.Header>Edit QA Flash Set</Modal.Header>
                <Modal.Body >
                    <div className='flex gap-3 items-center mb-4'>
                        <div className='inline-block align-middle font-bold text-xl'>Title: </div>
                        <input
                            className="shadow text-xl appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            placeholder="Enter a title for the wordset here..."
                            value={tempQASetGroup.name}
                            onChange={(e) => setTempQASetGroup({ ...tempQASetGroup, name: e.target.value })}
                        ></input>
                    </div>

                    <div className='grid grid-cols-2 gap-3 items-center mb-4'>
                        <div className='m-auto'>
                            <Button className='bg-col-p2' onClick={() => setShowCreateQASetModal(true)}>Add a Question/Answer Set</Button>
                        </div>
                        <div className='m-auto'>
                            <Button className='bg-col-p2' onClick={() => {
                                setShowTextToWordsModal(true);
                            }}>Add MULTIPLE Question/Answer Sets</Button>
                        </div>
                    </div>

                    {tempQASetGroup.qaSets && tempQASetGroup.qaSets.map((qaSet: QASet, i: number) => {
                        return <QAStub key={"qaStub" + i} qaSet={qaSet} index={i} />
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button className='bg-col-s1' onClick={() => setShowModal(false)}>Close</Button>
                    <Button
                        className='bg-col-p1'
                        disabled={tempQASetGroup.name === ''}
                        onClick={() => handlePersistWordSetGroup(tempQASetGroup)}

                    >Save</Button>
                </Modal.Footer>
            </Modal>

            {/* //ADD MULTIPLE QUESTION/ANSWER SETS */}
            <Modal show={showTextToWordsModal} onClose={() => setShowTextToWordsModal(false)}>
                <Modal.Header>Convert text to Q/A sets</Modal.Header>
                <Modal.Body>
                    <div className='mb-4'>Enter text below. Each question should start with a * and each answer should be on a new line. See the placeholder text below for an example.</div>
                    <Textarea rows={10} placeholder={tempWordsText} value={tempWords} onChange={(e) => setTempWords(e.target.value)}></Textarea>
                </Modal.Body>
                {parsingErrorMessage != '' && <div>
                    <Alert className='inline-flex mx-6' color="failure">
                        <span className="font-medium">Error!</span> {parsingErrorMessage}
                    </Alert>
                </div>}
                <Modal.Footer>

                    <Button className='bg-col-s1' onClick={() => { setTempWords(''); setShowTextToWordsModal(false) }}>Cancel</Button>
                    <Button disabled={tempWords === ''}
                        className='bg-col-p1'
                        onClick={() => {
                            let result = parseTextToWords();
                            if (result == 'success') {
                                //console.log('success');
                                setTempWords('');
                                setShowTextToWordsModal(false);
                                setParsingErrorMessage('');
                            } else {
                                setParsingErrorMessage(result);
                            }
                        }}>Convert to Q/A Sets</Button>

                </Modal.Footer>

            </Modal>

            {/* //CREATE/EDIT A SINGLE QUESTION/ANSWER SET */}
            <Modal size="3xl" show={showCreateQASetModal} onClose={() => setShowCreateQASetModal(false)}>
                <Modal.Header>New Question/Answer Set</Modal.Header>
                <Modal.Body>
                    <div className='flex gap-3 items-center mb-4'>
                        <div className='inline-block align-middle font-bold text-xl'>Question: </div>
                        <input
                            className="shadow text-xl appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            placeholder="Add a question..."
                            value={tempQASet.question}
                            onChange={(e) => setTempQASet({ ...tempQASet, question: e.target.value })}
                        ></input>
                    </div>

                    {tempQASet.answers.map((answer: string, i: number) => {
                        return <div key={'qaAnswer' + i}>
                            <div className='flex gap-2 items-center mb-4'>
                                <div className='inline-block align-middle font-bold whitespace-pre text-xl'>{`Answer ${i + 1}: `}</div>
                                <input
                                    className="shadow text-xl appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="Add an answer..."
                                    value={answer}
                                    onChange={(e) => {
                                        let newQASet = { ...tempQASet };
                                        newQASet.answers[i] = e.target.value;
                                        setTempQASet(newQASet);
                                    }}
                                ></input>
                                <Button
                                    className='w-16 p-1' outline color='failure'
                                    onClick={() => {
                                        let newQASet = { ...tempQASet };
                                        newQASet.answers.splice(i, 1);
                                        setTempQASet(newQASet);
                                    }}
                                >{courseIcons.renderDeleteIcon(24, 24)}</Button>
                            </div>
                        </div>
                    })}

                    <Button
                        className='bg-col-p2'
                        onClick={() => {
                            let newQASet = { ...tempQASet };
                            newQASet.answers.push('');
                            setTempQASet(newQASet);
                        }
                        }>Add answer</Button>

                </Modal.Body>
                <Modal.Footer>
                    <Button className='bg-col-s1' onClick={() => { setTempQASet(new QASet('', [''])); setShowCreateQASetModal(false) }}>Cancel</Button>
                    {/* <Button onClick={() => { parseTextToWords(); setTempWords(''); setShowCreateQASetModal(false) }}>Convert to Words</Button> */}
                    <Button
                        className='bg-col-p1'
                        onClick={() => {
                            if (editingQASetIndex > -1) {
                                let newQASetGroup = { ...tempQASetGroup };
                                newQASetGroup.qaSets[editingQASetIndex] = tempQASet;
                                setTempQASetGroup(newQASetGroup);
                                //setTempQASet(new QASet('', ['']));
                                setShowCreateQASetModal(false);
                                setEditingQASetIndex(-1);
                                return;
                            } else {
                                setShowCreateQASetModal(false);
                                let newQASetGroup = { ...tempQASetGroup };
                                newQASetGroup.qaSets.push(tempQASet);
                                setTempQASetGroup(newQASetGroup);
                            }
                        }}
                        disabled={tempQASet.question === '' || tempQASet.answers.length === 0 || tempQASet.answers.join().length <= 1}
                    >Save</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default CreateQASetGroupModal;